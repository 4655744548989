import React from 'react'
import { Alert, IconButton, Snackbar } from '@mui/material'
import { OpenSnackBarProps } from '../contexts/AppContext'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

export const SMSnackBar = ({
  open,
  setOpen,
  snackBarProps,
  ...rest
}: {
  open: boolean
  setOpen: (open: boolean) => void
  snackBarProps: OpenSnackBarProps
}) => {
  const handleClose = () => {
    setOpen(false)
  }
  const { type } = snackBarProps
  const backgroundColor = type === 'error' ? '#FEEEEE' : '#ECFCEF'
  const borderColor = type === 'error' ? '#F45151' : '#3EE25F'

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
      autoHideDuration={snackBarProps.duration || 2000}
      sx={{ minWidth: '70%' }}
      {...rest}
    >
      <Alert
        severity="success"
        icon={<CheckCircleRoundedIcon fontSize="small" sx={{ color: borderColor }} />}
        sx={{
          width: '100%',
          border: `1px solid ${borderColor}`,
          backgroundColor,
          borderRadius: '8px',
        }}
        action={
          <IconButton
            component="button"
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        }
      >
        {snackBarProps.message}
      </Alert>
    </Snackbar>
  )
}

// Este comentario me imagino que es un error xq si esta en las dependencias
import React, { useContext, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded'

import styles from './discountsHolder.module.css'
import DiscountCard from './DiscountCard'
import { Discount } from '../../../types/discount'
import { neutralWhite } from '../../../style'
import { logSMEvent } from '../../../events/logEvent'
import { MAINTAGV3Enum } from '../../../types/mainTagV3'
import { AppContext } from '../../../contexts/AppContext'
import { filterAdsToShowInList } from './ads/helpers'
import { Ad } from '../../../types/ads'
import { useFilterOptions } from '../../../hooks/filterOptions'
import { DiscountsPromotedOrBanner } from './ads/DiscountsPromotedOrBanner'

const { discountAndDescriptionFrame, discountsHolder, finishDot } = styles

const DISCOUNTS_TO_RENDER_EACH_LOADING = 12 // (3x4) so it can display nice in any screen

const DiscountsHolder = function ({
  discountsToShow,
  category,
  isStar,
}: {
  discountsToShow: Discount[]
  category: MAINTAGV3Enum
  isStar: boolean
}) {
  const { ads, filtersSelected } = useContext(AppContext)
  const filterOptions = useFilterOptions()
  const [amountDiscountsToRender, setAmountDiscountsToRender] = useState(
    DISCOUNTS_TO_RENDER_EACH_LOADING
  )
  const [bannersToShow, setBannersToShow] = useState<Ad[]>([])

  useEffect(() => {
    const bannersToShow = filterAdsToShowInList(ads, category, filtersSelected, filterOptions)
    setBannersToShow(bannersToShow)
  }, [ads])

  const [promotedDiscounts, setPromotedDiscounts] = useState<Discount[]>([])

  useEffect(() => {
    setPromotedDiscounts(discountsToShow.filter((discount) => discount.promoted))
  }, [discountsToShow])

  return (
    <div className={discountAndDescriptionFrame}>
      <div className={discountsHolder}>
        {discountsToShow?.slice(0, amountDiscountsToRender).map((discount, index) => {
          return (
            <React.Fragment key={discount.id}>
              <DiscountsPromotedOrBanner
                bannersToShow={bannersToShow}
                index={index}
                promotedDiscounts={promotedDiscounts}
              />
              <DiscountCard discount={discount} category={category} isStar={isStar} />
            </React.Fragment>
          )
        })}
      </div>
      {amountDiscountsToRender >= discountsToShow?.length ? (
        <div className={finishDot} />
      ) : (
        <Button
          onClick={() => {
            logSMEvent('VER_MAS_DESCUENTOS', {
              category,
            })
            setAmountDiscountsToRender(amountDiscountsToRender + DISCOUNTS_TO_RENDER_EACH_LOADING)
          }}
          sx={{
            borderRadius: 40,
            padding: '4px 16px',
            margin: '20px 0',
            display: 'flex',
            backgroundColor: neutralWhite,
            flex: 1,
            boxShadow: '2px 2px 4px rgba(70,70,70,0.25)',
            alignItems: 'center',
            textTransform: 'none',
          }}
        >
          Ver más
          <ExpandMoreRounded />
        </Button>
      )}
    </div>
  )
}

export default DiscountsHolder

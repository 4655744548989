import React, { useState, useEffect, useContext } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { AppContext, UserAuthType } from '../../../../contexts/AppContext'
import ButtonTracked from '../../../common/ButtonTracked'
import { SMMuiTelInput, SMTextField } from '../../../../SMComponents/SMInput'
import { formatRut, isValidPhoneNumber, isValidRut } from '../../../../helpers/validateForms'
import { request } from '../../../../helpers/request'
import { logSMEvent } from '../../../../events/logEvent'
import { Checkbox, FormControlLabel } from '@mui/material'
import Link from '@mui/material/Link'

export const LoginDialog = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (newState: any) => void
}) => {
  const { auth, setAuth, handleOpenSnackBar, user } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [rut, setRut] = useState((auth && auth?.rut) || '')
  const [phoneNumber, setPhoneNumber] = useState<string>(
    auth && auth?.phoneNumber ? auth?.phoneNumber : '+56'
  )
  const [termsAccepted, setTermsAccepted] = useState(false)
  const includePhoneNumber = !(auth && auth?.phoneNumber)
  const includeRut = !(auth && auth?.rut)

  useEffect(() => {
    // auth puede que se cargue más lento que el componente
    if (auth && auth?.rut) setRut(auth.rut)
    if (auth && auth?.phoneNumber) setPhoneNumber(auth.phoneNumber)
  }, [auth])

  useEffect(() => {
    if (open) {
      logSMEvent('OPEN_LOGIN_DIALOG', { auth })
    }
  }, [open])

  const handleRutChange = (e: any) => {
    const rut = formatRut(e.target.value)
    setRut(rut)
  }

  const handleButtonClick = async () => {
    setIsLoading(true)
    request('create_user', {
      method: 'POST',
      body: JSON.stringify({
        rut,
        phoneNumber,
        userFrontendRandomId: user,
        source: 'login_dialog',
      }),
    }).then((response) => {
      const responseAuth = response.data as UserAuthType
      setAuth(responseAuth)
    })
    logSMEvent('SUCCESS_CLOSE_LOGIN_DIALOG')
    handleOpenSnackBar({
      message: '¡Bienvenido! Explora y guarda tus beneficios favoritos',
      duration: 2000,
    })
    setOpen(false)
    setIsLoading(false)
  }

  const isValidForm = () => {
    if (includeRut && !isValidRut(rut)) return false
    if (includePhoneNumber && !isValidPhoneNumber(phoneNumber)) return false
    if (!termsAccepted) return false
    return true
  }

  const handlePhoneNumberChange = (newValue: string) => {
    setPhoneNumber(newValue)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        logSMEvent('TRY_TO_CLOSE_LOGIN_DIALOG')
      }}
      PaperProps={{
        component: 'form',
      }}
    >
      <DialogTitle>¡Únete a SaveMoney!</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 3 }}>
          Para seguir usando SaveMoney, necesitas una cuenta. Así podrás guardar tus descuentos
          favoritos, reservar mesas y acceder a beneficios exclusivos. ¡Es rápido y fácil!
        </DialogContentText>
        {includeRut && (
          <SMTextField
            margin="dense"
            sx={{ marginTop: 1, width: '100%' }}
            autoFocus // this is to focus the input when the dialog opens
            validateOnBlur={isValidRut}
            textOnBlurError="Rut inválido"
            type="numeric"
            label="Rut"
            value={rut}
            onChange={handleRutChange}
            // disabled={auth?.rut}
          />
        )}
        {includePhoneNumber && (
          <SMMuiTelInput
            sx={{ marginTop: 1, width: '100%' }}
            validateOnBlur={isValidPhoneNumber}
            textOnBlurError="Número inválido"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              name="terms"
              color="primary"
            />
          }
          sx={{ marginTop: 1 }}
          label={
            <>
              Acepto los{' '}
              <Link href="https://www.savemoney.cl/terminos.html" target="_blank" rel="noopener">
                términos y condiciones
              </Link>{' '}
              sobre privacidad de los datos
            </>
          }
        />
      </DialogContent>
      <DialogActions>
        <ButtonTracked
          disabled={!isValidForm() || isLoading}
          buttonname={`login_dialog_2`}
          variant="contained"
          onClick={() => {
            return handleButtonClick()
          }}
          sx={{
            fontWeight: 'bold',
            textTransform: 'none',
            margin: '0px 20px 20px 0px;',
            padding: '4px 20px',
            fontSize: '1rem',
          }}
        >
          {isLoading ? 'Cargando...' : 'Crear y validar cuenta'}
        </ButtonTracked>
      </DialogActions>
    </Dialog>
  )
}

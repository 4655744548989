import React, { useState } from 'react'
import { primaryPrimary } from '../../../style'
import { logSMEvent } from '../../../events/logEvent'
import { ReactComponent as InstagramIcon } from '../../../assets/instagramIcon.svg'
import { CircularProgress } from '@mui/material'
import { SMVerticalDiv } from '../../../SMComponents/SMView'

const clubsToShowWhileLoading = [
  'Bci',
  'BICE',
  'Banco Estado',
  'Banco Falabella',
  'Banco Ripley',
  'Santander',
  'Security',
  'Scotiabank',
  'Itaú',
  'Chek',
  'Banco De Chile',
  'Full Copec',
  'MACH',
  'Entel',
  'Vecino Las Condes',
  'La Reina',
  'La Tercera',
  'El Mercurio',
  'Otros...',
]

export const LoadingDiscountsScreen = () => {
  const [messageToShowWhileLoading, setMessageToShowWhileLoading] = useState<string>(
    clubsToShowWhileLoading[0]
  )

  // every 0.3 seconds, change the message to the next clubs in the list clubsToShowWhileLoading
  // first in order, then if it reaches the end, leave the last one
  setTimeout(() => {
    setMessageToShowWhileLoading(
      clubsToShowWhileLoading[
        Math.min(
          clubsToShowWhileLoading.indexOf(messageToShowWhileLoading) + 1,
          clubsToShowWhileLoading.length - 1
        )
      ]
    )
  }, 580)

  return (
    <div
      style={{
        display: 'flex',
        height: '96vh', // almost all
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <SMVerticalDiv sx={{ gap: 40, margin: 40 }}>
        <p>Cargando descuentos de: {messageToShowWhileLoading}</p>
        <CircularProgress style={{ margin: 'auto' }} />
      </SMVerticalDiv>
      <div style={{ display: 'flex' }}>
        Siguenos
        <a
          href="https://www.instagram.com/savemoney.cl/"
          target="_blank"
          rel="noreferrer"
          style={{ color: primaryPrimary, display: 'flex', marginLeft: 3 }}
          onClick={() => logSMEvent('CLICK_FOLLOW_US', { page: 'table_loading' })}
        >
          @SaveMoney.cl
          <InstagramIcon
            style={{
              margin: '2px 0px 0px 2px',
              fill: primaryPrimary,
              height: '20px',
            }}
          />
        </a>
      </div>
    </div>
  )
}

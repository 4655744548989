import React, { useContext, useEffect, useState } from 'react'
import { SMBottomDrawer } from '../../../SMComponents/SMDrawer'
import { SMContent, SMVerticalDiv } from '../../../SMComponents/SMView'
import { SMH2, SMP } from '../../../SMComponents/SMText'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { SMMuiTelInput, SMTextField } from '../../../SMComponents/SMInput'
import { OpenAdDrawerProps } from '../../../contexts/DiscountContext'
import { logSMEvent } from '../../../events/logEvent'
import { AppContext, UserAuthType } from '../../../contexts/AppContext'
import { Checkbox, CircularProgress, FormControlLabel, Link, Snackbar } from '@mui/material'
import { primaryPrimary } from '../../../style'
import { formatRut, isValidPhoneNumber, isValidRut } from '../../../helpers/validateForms'
import { request } from '../../../helpers/request'
import { pluralize } from '../../../helpers/normalizeText'
import { SMShareButton } from '../../common/SMButton'

export const AdDrawer = ({
  open,
  setOpen,
  adDrawerProps,
}: {
  open: boolean
  setOpen: (newState: boolean) => void
  adDrawerProps: OpenAdDrawerProps
}) => {
  const { handleOpenSnackBar, user: userFrontendRandomId, auth, setAuth } = useContext(AppContext)
  const [rut, setRut] = useState(auth && auth.rut ? auth.rut : '')
  const [name, setName] = useState(auth && auth.name ? auth.name : '')
  const [phoneNumber, setPhoneNumber] = useState<string>(
    auth && auth?.phoneNumber ? auth?.phoneNumber : '+56'
  )
  const [copied, setCopied] = useState(false)

  const [termsAccepted, setTermsAccepted] = useState(false)
  const [isLoadingLogin, setIsLoadingLogin] = useState(true)
  const [referralUrl, setReferralUrl] = useState<string>('')

  const shouldAskForRut = !auth || !auth.rut
  const shouldAskForName = !auth || !auth.name
  const shouldAskForPhoneNumber = !auth || !auth.phoneNumber

  useEffect(() => {
    if (auth) {
      const baseUrl = 'https://www.savemoney.cl'
      const url = new URL(baseUrl)
      url.searchParams.append('referral', auth.referralCode || '')
      url.searchParams.append('referralName', auth.name || '')
      const textToCopy = url.toString()
      setReferralUrl(textToCopy)
    }
  }, [auth])

  useEffect(() => {
    if (open) {
      // hacer login otra vez para obtener la última info actualizada del usuario.
      // esto es necesario para poder tener los referrals más actualizados
      const login = async (userId: string, secretToken: string) => {
        const response = await request('login_user', {
          method: 'POST',
          body: JSON.stringify({
            userId,
            secretToken,
          }),
        }) // este endpoint se asegura de generar un referral code si no lo tiene
        const responseAuth = response.data
        setAuth(responseAuth)

        if (responseAuth && responseAuth.referralCode === 'ERROR') {
          logSMEvent('ERROR', { message: 'Error al generar el referral code' })
          handleOpenSnackBar({
            message: 'Lo sentimos hubo un error. Por favor intenta más tarde.',
            duration: 4000,
          })
        }
      }
      setIsLoadingLogin(true)
      const userId = auth && auth?.id
      const secretToken = auth && auth?.secretToken
      if (secretToken && userId) {
        login(userId, secretToken).then(() => {
          setIsLoadingLogin(false)
        })
      } else {
        setAuth(false)
        setIsLoadingLogin(false)
      }
    }
  }, [open])

  useEffect(() => {
    // auth puede que se cargue más lento que el componente
    if (auth && auth?.rut) setRut(auth.rut)
    if (auth && auth?.name) setName(auth.name)
    if (auth && auth?.phoneNumber) setPhoneNumber(auth.phoneNumber)
  }, [auth])

  const handleRutChange = (e: any) => {
    const rut = formatRut(e.target.value)
    setRut(rut)
  }

  useEffect(() => {
    if (open) {
      logSMEvent('OPEN_AD_DRAWER', {
        ad_id: adDrawerProps.ad?.id,
        user_id: userFrontendRandomId,
        auth_id: auth ? auth?.id || '' : '',
      })
    }
  }, [open])

  const handleLoginButtonClicked = () => {
    logSMEvent('AD_USER_LOGIN_FOR_PARTICIPATION', {
      user_name: name,
      user_rut: rut,
      user_phone_number: phoneNumber,
      ...adDrawerProps.logImpressionParams,
    })
    setIsLoadingLogin(true)
    request('create_user', {
      method: 'POST',
      body: JSON.stringify({
        name,
        phoneNumber: phoneNumber === '+56' ? '' : phoneNumber,
        rut,
        userFrontendRandomId,
        source: 'ad_drawer',
      }),
    }).then((response) => {
      handleOpenSnackBar({
        message: 'Listo! ya puedes compartir tu enlace.',
        duration: 4000,
      })
      const responseAuth = response.data as UserAuthType
      setAuth(responseAuth)
      setIsLoadingLogin(false)
    })
  }

  const handlePhoneNumberChange = (newValue: string) => {
    setPhoneNumber(newValue)
  }

  const isValidForm = () => {
    if (name === '') return false
    if (!isValidRut(rut)) return false
    if (!isValidPhoneNumber(phoneNumber)) return false
    if (!termsAccepted) return false
    return true
  }

  const handleFocus = () => {
    if (auth && auth.referralCode && auth.name) {
      logSMEvent('FOCUS_REFERRAL_CODE', { referralCode: auth.referralCode, user_id: auth.id })
      navigator?.clipboard
        ?.writeText(referralUrl)
        ?.then(() => {
          setCopied(true) // Show the snackbar
        })
        ?.catch((err) => {
          console.error('Failed to copy text: ', err)
        })
    } else {
      logSMEvent('ERROR', {
        message: 'Focus in referralCode without an auth! WTF',
        id: 'REFERRAL_LOTTERY',
      })
    }
  }

  const handleCloseSnackbar = () => {
    setTimeout(() => setCopied(false), 500) // Wait 300ms before resetting this is because of an issue in Safari only
  }

  const referredToAmount = auth ? auth?.referredTo?.length || 0 : 0

  return (
    <SMBottomDrawer
      open={open}
      onClose={() => {
        logSMEvent('CLOSE_AD_DRAWER', {
          ad_id: adDrawerProps.ad?.id,
          user_id: userFrontendRandomId,
        })
        setOpen(false)
      }}
      onOpen={() => setOpen(true)}
      sx={{ zIndex: 300 }}
    >
      <SMContent
        sx={{
          overflowY: 'auto', // to allow scrolling
        }}
      >
        <SMVerticalDiv>
          <SMH2 center>🎁 ¡Participa en nuestro sorteo de $100.000! 🎁</SMH2>
          <SMP>
            ¡Hola! Estamos sorteando $100.000 entre los usuarios que nos ayuden a correr la voz
            sobre SaveMoney. ¡Participa y gana!
          </SMP>
          <SMP>
            📅 <strong>Fecha del sorteo:</strong> 18 de diciembre a las 20:00 hrs. <br />
            🏆 <strong>Premio:</strong> Depositamos $100.000 en la cuenta bancaria del ganador. ¡Sin
            peros!
            <br /> 📜 <strong>Bases legales del concurso:</strong>{' '}
            <Link href="https://www.savemoney.cl/bases_concurso.pdf" target="_blank" rel="noopener">
              en este enlace
            </Link>
            <br />
            🥳 <strong>Anuncio del ganador:</strong> Haremos el concurso y publicaremos al ganador a
            través de nuestro{' '}
            <a
              href="https://www.instagram.com/savemoney.cl/"
              target="_blank"
              rel="noreferrer"
              style={{ color: primaryPrimary, marginLeft: 3 }}
              onClick={() => logSMEvent('CLICK_FOLLOW_US_SORTEO_INSTAGRAM')}
            >
              Instagram (@SaveMoney.cl)
            </a>
            <br />
          </SMP>
          <SMP>
            <strong>Instrucciones:</strong> Comparte tu enlace con alguien que nunca haya usado
            SaveMoney. Cada vez que alguien ingrese con tu enlance, obtendrás una nueva oportunidad
            para ganar el sorteo. ¡Mientras más compartas, más veces participas! 🍀
          </SMP>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            {isLoadingLogin || auth === null ? (
              <SMVerticalDiv horizontalCenter>
                <CircularProgress style={{ margin: 'auto' }} />
              </SMVerticalDiv>
            ) : shouldAskForName || shouldAskForPhoneNumber || shouldAskForRut || !auth ? (
              <SMVerticalDiv>
                <SMP>Para generar tu enlace y participar del sorteo primero necesitamos:</SMP>
                {shouldAskForName && (
                  <SMTextField
                    validateOnBlur={!name}
                    label={`Tu nombre para publicarlo si ganas`}
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                  />
                )}
                {shouldAskForPhoneNumber && (
                  <SMMuiTelInput
                    label="Teléfono de contacto para recibir el premio"
                    validateOnBlur={isValidPhoneNumber}
                    textOnBlurError="Número inválido"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                  />
                )}
                {shouldAskForRut ? (
                  <SMTextField
                    validateOnBlur={isValidRut}
                    textOnBlurError="Rut inválido"
                    type="numeric"
                    label="Rut"
                    value={rut}
                    onChange={handleRutChange}
                    // disabled={auth?.rut}
                  />
                ) : null}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      name="terms"
                      color="primary"
                    />
                  }
                  label={
                    <SMP>
                      Acepto las{' '}
                      <Link
                        href="https://www.savemoney.cl/terminos.html"
                        target="_blank"
                        rel="noopener"
                      >
                        política de privacidad de datos.
                      </Link>
                    </SMP>
                  }
                />
                <SMPrimaryButton
                  onClick={handleLoginButtonClicked}
                  disabled={!isValidForm()}
                  sx={{ marginTop: 10 }}
                >
                  Enviar y generar enlace
                </SMPrimaryButton>
              </SMVerticalDiv>
            ) : (
              <SMVerticalDiv horizontalCenter>
                <SMP>Tu enlace para compartir es:</SMP>
                <SMVerticalDiv horizontalCenter>
                  <Snackbar
                    open={copied}
                    autoHideDuration={1000}
                    onClose={handleCloseSnackbar}
                    message="Copiado!"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  />
                  <SMTextField
                    slotProps={{
                      input: {
                        readOnly: true,
                      },
                    }}
                    value={referralUrl}
                    sx={{ minWidth: '300px' }}
                    onFocus={handleFocus}
                  />
                  <SMShareButton
                    title=""
                    text={`
¡Hola! Quiero contarles sobre esta startup, SaveMoney \n
Es una página y app para encontrar todos los descuentos que tenemos con tarjetas de crédito y otros convenios, ideal para ahorrar y super fácil de usar.\n
Si usan este enlace para conocerla, me estarán ayudando:
${referralUrl}
\n¡Gracias!`}
                    onClick={() => {
                      logSMEvent('CLICK_SHARE_LOTTERY_BUTTON', {
                        user_name: name,
                        user_rut: rut,
                        user_phone_number: phoneNumber,
                        referralCode: auth?.referralCode,
                        ...adDrawerProps.logImpressionParams,
                      })
                      if (adDrawerProps?.logImpressionParams?.ad_id) {
                        request('ad/convertion', {
                          method: 'POST',
                          body: JSON.stringify({
                            adId: adDrawerProps?.logImpressionParams?.ad_id,
                          }),
                        })
                      } else {
                        logSMEvent('ERROR', {
                          message: 'No ad_id in logImpressionParams when sharing',
                          id: 'REFERRAL_LOTTERY',
                        })
                      }
                    }}
                    buttonName="share_button"
                    sx={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                      minHeight: 'fit-content',
                      maxHeight: 'fit-content',
                    }}
                  >
                    Compartir
                  </SMShareButton>
                </SMVerticalDiv>
                {referredToAmount >= 0 ? (
                  <SMP>
                    ¡Felicitaciones! {referredToAmount} {pluralize('persona', referredToAmount)}{' '}
                    {pluralize('ha', referredToAmount)} usado tu enlace. Esto significa que ya estas
                    participando y tienes {referredToAmount}{' '}
                    {pluralize('oportunidad', referredToAmount)} de ganar. ¡Sigue compartiendo para
                    más oportunidades!
                  </SMP>
                ) : (
                  <SMP>Cantidad de usuario que han ingresado con tu enlace: 0</SMP>
                )}
              </SMVerticalDiv>
            )}
          </div>

          <SMP>
            Para cualquier duda contáctanos a nuestro{' '}
            <Link
              href="https://wa.me/56974769848"
              target="_blank"
              rel="noopener"
              sx={{ textDecoration: 'none' }}
            >
              WhatsApp.
            </Link>{' '}
            ¡Mucha suerte! 🍀
          </SMP>
        </SMVerticalDiv>
      </SMContent>
    </SMBottomDrawer>
  )
}

import React, { useState, useEffect, useContext } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { AppContext, UserAuthType } from '../../../../contexts/AppContext'
import ButtonTracked from '../../../common/ButtonTracked'
import { SMTextField } from '../../../../SMComponents/SMInput'
import { formatRut, isValidRut } from '../../../../helpers/validateForms'
import { request } from '../../../../helpers/request'
import { logSMEvent } from '../../../../events/logEvent'
import { Checkbox, FormControlLabel } from '@mui/material'
import Link from '@mui/material/Link'
import { capitalizeEveryWord } from '../../../../helpers/normalizeText'

export const LoginReferralDialog = ({
  isFirstUser,
  referralCode,
  referralName,
}: {
  isFirstUser: boolean
  referralCode?: string
  referralName?: string
}) => {
  const { auth, setAuth, handleOpenSnackBar, user } = useContext(AppContext)

  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [rut, setRut] = useState((auth && auth?.rut) || '')
  const [termsAccepted, setTermsAccepted] = useState(false)

  useEffect(() => {
    if (referralCode && isFirstUser) {
      setOpen(true)
      logSMEvent('OPEN_LOGIN_REFERRAL_DIALOG', { referralCode })
    } else if (referralCode) {
      logSMEvent('TRY_REFERRAL_WITH_KNOWN_USER', { referralCode })
      handleOpenSnackBar({
        message:
          '¡Ups! Parece que ya habías visitado nuestro sitio, así que el código de referido de tu amigo no aplica esta vez.',
        duration: 14000,
        type: 'error',
      })
    }
  }, [])

  const handleRutChange = (e: any) => {
    const rut = formatRut(e.target.value)
    setRut(rut)
  }

  const handleButtonClick = async () => {
    setIsLoading(true)
    request('create_user', {
      method: 'POST',
      body: JSON.stringify({
        rut,
        userFrontendRandomId: user,
        source: 'referred_dialog',
        signupReferredCode: referralCode,
      }),
    }).then((response) => {
      const responseAuth = response.data as UserAuthType
      if (response.data?.isReferralSuccessful) {
        handleOpenSnackBar({
          message: '¡Bienvenido a SaveMoney! Hemos registrado tu referido',
          duration: 2000,
        })
        logSMEvent('REFERRAL_LOGIN_SUCCESS', { userId: response.data?.id, referralCode })
      } else if (response.data?.isNew === false) {
        // si es que ingresó RUT que ya existe
        logSMEvent('REFERRAL_LOGIN_WITH_EXISTING_RUT', { userId: response.data?.id })
        handleOpenSnackBar({
          message:
            '¡Bienvenido nuevamente a SaveMoney! Detectamos que ya estabas registrado, por lo que la referencia no se aplicará. ¡Nos alegra tenerte de vuelta!',
          duration: 10000,
        })
      } else {
        // si código de referido no existe
        handleOpenSnackBar({
          message: '¡Bienvenido a SaveMoney! Pero tu código de referencia no es válido.',
          duration: 10000,
        })
        logSMEvent('ERROR', {
          message: 'Login con referralCode que no existe: ' + referralCode,
        })
      }
      setAuth(responseAuth)
      logSMEvent('SUCCESS_CLOSE_LOGIN_DIALOG', {
        isReferralSuccessful: response.data?.isReferralSuccessful,
        newUserId: responseAuth?.id,
        referralCode,
      })
    })
    setOpen(false)
    setIsLoading(false)
  }

  const isValidForm = () => {
    if (!isValidRut(rut)) return false
    if (!termsAccepted) return false
    return true
  }

  if (!referralCode || auth) {
    return null
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        logSMEvent('TRY_TO_CLOSE_REFERRAL_DIALOG')
        handleOpenSnackBar({
          message: '¡Regístrate para que podamos premiar a quien te refirió! 🎉',
          duration: 4000,
        })
      }}
      PaperProps={{
        component: 'form',
      }}
    >
      <DialogTitle>¡Bienvenido a SaveMoney!</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 3 }}>
          Has sido referido por <strong>{capitalizeEveryWord(referralName)}</strong>. Para ayudarlo
          a ganar una recompensa unete a nuestra comunidad.
        </DialogContentText>
        <SMTextField
          margin="dense"
          sx={{ marginTop: 1, width: '100%' }}
          autoFocus // this is to focus the input when the dialog opens
          validateOnBlur={isValidRut}
          textOnBlurError="Rut inválido"
          type="numeric"
          label="Tu Rut"
          value={rut}
          onChange={handleRutChange}
          // disabled={auth?.rut}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              name="terms"
              color="primary"
            />
          }
          sx={{ marginTop: 1 }}
          label={
            <>
              Acepto los{' '}
              <Link href="https://www.savemoney.cl/terminos.html" target="_blank" rel="noopener">
                términos y condiciones
              </Link>{' '}
              sobre privacidad de los datos
            </>
          }
        />
      </DialogContent>
      <DialogActions>
        <ButtonTracked
          disabled={!isValidForm() || isLoading}
          buttonname={`login_referral_dialog`}
          variant="contained"
          onClick={() => {
            return handleButtonClick()
          }}
          sx={{
            fontWeight: 'bold',
            textTransform: 'none',
            margin: '0px 20px 20px 0px;',
            padding: '4px 20px',
            fontSize: '1rem',
          }}
        >
          {isLoading ? 'Cargando...' : 'Continuar'}
        </ButtonTracked>
      </DialogActions>
    </Dialog>
  )
}

import { DaysEnums } from '../consts/filterOptions'
import { ClubEnums } from './clubs'
import { MAINTAGV3Enum } from './mainTagV3'
import { RegionesEnum } from './regiones'

type RulesType = {
  Tarjeta?: ClubEnums
  Dias?: DaysEnums[]
  Ubicacion?: RegionesEnum[]
  Category?: MAINTAGV3Enum
}

type ImagesType = {
  desktop: string
  mobile: string
}

export enum AdTypeEnum {
  BANNER = 'BANNER',
  DISCOUNT_LIST_AD = 'DISCOUNT_LIST_AD',
}

export type Ad = {
  id: string // viene del backend
  targetVersionBackend: string // viene del backend
  MIN_FRONTEND_VERSION?: number // para filtrar desde el backend que ads se muestran para que vesiones
  MAX_FRONTEND_VERSION?: number // para filtrar desde el backend que ads se muestran para que vesiones
  images?: ImagesType
  display?: boolean
  order?: number
  url?: string
  onClick?: string
  rules?: RulesType
  adType?: AdTypeEnum
}

import React from 'react'
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
} from '@mui/material'
import { appVersion } from '../../../consts/version'
import {
  isiOS,
  isAndroid,
  isInSafari,
  isStandAlone,
  isStandAlone2,
  isAndroid2,
  isSamsung,
  isMinimalUI,
  isAppInstalled,
  isInstagram,
  isChrome,
  isMobile,
  isPwaModeParamActive,
} from '../../../helpers/device'
import { primary20White } from '../../../style'

export const DeviceInfo = function () {
  const infoItems = [
    { label: 'Versión de la App', value: appVersion },
    { label: 'iOS', value: isiOS },
    { label: 'Android', value: isAndroid },
    { label: 'Safari', value: isInSafari },
    { label: 'StandAlone', value: isStandAlone },
    { label: 'StandAlone2', value: isStandAlone2 },
    { label: 'Android2', value: isAndroid2 },
    { label: 'Samsung', value: isSamsung },
    { label: 'MinimalUI', value: isMinimalUI },
    { label: 'App Instalada', value: isAppInstalled },
    { label: 'Instagram', value: isInstagram },
    { label: 'Chrome', value: isChrome },
    { label: 'Mobile', value: isMobile },
    { label: 'PWA Param', value: isPwaModeParamActive },
  ]

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: '100vh', bgcolor: primary20White, padding: 2 }}
    >
      <Card sx={{ maxWidth: 600, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h2" component="div" gutterBottom sx={{ fontSize: '0.5rem' }}>
            Información del Dispositivo
          </Typography>
          <List sx={{ fontSize: '0.5rem' }}>
            {infoItems.map((item, index) => (
              <React.Fragment key={item.label}>
                <ListItem sx={{ padding: 0.5 }}>
                  <ListItemText
                    primary={item.label}
                    secondary={String(item.value)}
                    primaryTypographyProps={{ fontSize: '0.5rem' }}
                    secondaryTypographyProps={{ fontSize: '0.5rem', color: 'text.secondary' }}
                  />
                </ListItem>
                {index < infoItems.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </CardContent>
      </Card>
    </Box>
  )
}

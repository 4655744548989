export const normalizeText = (text: string) => {
  if (!text) return ''
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const capitalizeFirstChar = (text: string) => {
  if (!text) return ''
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const capitalizeEveryWord = (text?: string) => {
  if (!text) return ''
  return text
    .split(' ')
    .map((word) => capitalizeFirstChar(word))
    .join(' ')
}

const pluraizeWordsExceptions: {
  [key: string]: string
} = {
  ha: 'han',
  oportunidad: 'oportunidades',
}

export const pluralize = (textInSingular: string, count: number) => {
  if (count === 1) return textInSingular
  if (pluraizeWordsExceptions[textInSingular]) return pluraizeWordsExceptions[textInSingular]
  return `${textInSingular}s`
}

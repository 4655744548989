import React from 'react'
import { Ad } from '../../../../types/ads'
import { Discount } from '../../../../types/discount'
import { PromotedDiscount } from './PromotedDiscount'
import { LIST_DISCOUNT_CARD_STYLE } from '../../../../consts/stylesConsts'
import { Banner } from '../Banner'

const AMOUNT_DISCOUNTS_BY_AD = 3

export const DiscountsPromotedOrBanner = ({
  index,
  bannersToShow,
  promotedDiscounts,
}: {
  index: number
  bannersToShow: Ad[]
  promotedDiscounts: Discount[]
}) => {
  // mostramos un ad or banner cada 3
  if (index % AMOUNT_DISCOUNTS_BY_AD !== 0) return null
  const indexInAdList = index / AMOUNT_DISCOUNTS_BY_AD

  const showPromoteDiscount = promotedDiscounts.length > indexInAdList
  const bannerIndex = indexInAdList - 1 // dado que index > 0 se salta el primero.
  const showBanner = bannersToShow.length > bannerIndex && index > 0 // no mostramos un banner al principio de la lista

  return (
    <>
      {
        // Show promoted discounts
        showPromoteDiscount && <PromotedDiscount discount={promotedDiscounts[indexInAdList]} />
      }
      {
        // Show ad
        showBanner && (
          <div
            style={{
              ...LIST_DISCOUNT_CARD_STYLE,
              height: '196px',
              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
            }}
          >
            <Banner
              ad={bannersToShow[bannerIndex]}
              isActive={true}
              index={1}
              imageUrl={bannersToShow[bannerIndex].images?.mobile}
            />
          </div>
        )
      }
    </>
  )
}

import React, { useState, ChangeEvent } from 'react'
import {
  Box,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Link,
  Snackbar,
  Alert,
} from '@mui/material'
import { request } from '../../../helpers/request'

interface CopyButtonProps {
  url: string
}

const CopyToClipboardButton: React.FC<CopyButtonProps> = ({ url }) => {
  const [open, setOpen] = useState(false)

  const handleCopy = async (): Promise<void> => {
    try {
      await navigator.clipboard.writeText(url)
      setOpen(true) // Show notification
    } catch (error) {
      console.error('Failed to copy to clipboard:', error)
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') return // Prevent close on clickaway
    setOpen(false)
  }

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleCopy}>
        Copy URL
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={800}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} variant="filled">
          URL copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  )
}

// Define response type for clarity
interface ApiResponse {
  status: string
  data: {
    imageUrl: string
  }
}

export const AdminAdsPanel: React.FC = () => {
  const [file, setFile] = useState<File | null>(null)
  const [base64, setBase64] = useState<string>('')
  const [response, setResponse] = useState<ApiResponse | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  // Handle file selection and Base64 conversion
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const selectedFile = event.target.files?.[0] || null
    setFile(selectedFile)
    setResponse(null)
    setBase64('')

    if (selectedFile) {
      const reader = new FileReader()
      reader.onload = () => {
        const result = reader.result as string
        setBase64(result.split(',')[1]) // Extract Base64 string
      }
      reader.readAsDataURL(selectedFile)
    }
  }

  // Handle sending the file to the endpoint
  const handleSend = async (): Promise<void> => {
    if (!base64) {
      alert('No file selected or Base64 conversion failed.')
      return
    }

    setLoading(true)

    try {
      const randomName = Math.random().toString(36).substring(7)
      const response = await request('admin/upload_image', {
        method: 'POST',
        body: JSON.stringify({
          image: base64,
          fileName: randomName + file?.name,
        }),
      })
      setResponse(response)
    } catch (error) {
      alert('Error uploading file.')
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Admin Panel
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* File Selection */}
          <Button variant="contained" component="label">
            Select File
            <input type="file" hidden onChange={handleFileChange} accept="*/*" />
          </Button>
          {file && <Typography variant="body2">Selected File: {file.name}</Typography>}
          {/* Send Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSend}
            disabled={!base64 || loading}
          >
            {loading ? <CircularProgress size={24} /> : 'SEND'}
          </Button>
          {/* Response Display */}
          {response?.data?.imageUrl && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="body2">
                <strong>Image URL:</strong>{' '}
                <Link href={response.data.imageUrl} target="_blank">
                  {response.data.imageUrl}
                </Link>
              </Typography>
              <CopyToClipboardButton url={response.data.imageUrl} />

              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>Image:</strong>
              </Typography>
              <Box
                sx={{
                  maxHeight: 200,
                  overflow: 'auto',
                  p: 1,
                  border: '1px solid #ddd',
                  backgroundColor: '#f9f9f9',
                }}
              >
                <img src={response.data.imageUrl} alt="Uploaded" style={{ maxWidth: '100%' }} />
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

import React, { useContext, forwardRef, CSSProperties } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import IconButton from '@mui/material/IconButton'
import { Button } from '@mui/material'
import { logSMEvent } from '../events/logEvent'
import { AppContext } from '../contexts/AppContext'

interface SMPrimaryButtonProps extends React.ComponentProps<typeof LoadingButton> {
  b?: boolean // Optional: Adds a border if true
  href?: string // For rendering as a link button
  fullWidth?: boolean // If true, button takes full width
  maxWidth?: boolean // Maximum width of the button
  small?: boolean // If true, renders a smaller button
  sx?: object // Additional styles for the sx prop
}

export const SMPrimaryButton = forwardRef<HTMLButtonElement, SMPrimaryButtonProps>(
  ({ b, href, fullWidth, maxWidth, children, style, small, sx, ...rest }, ref) => {
    const buttonStyles: CSSProperties = {
      borderRadius: 40,
      padding: small ? 16 : 20,
      gap: 4,
      height: small ? 30 : 55,
      textTransform: 'none',
      width: fullWidth ? '100%' : undefined,
      maxWidth: maxWidth ? '384px' : undefined,
      border: b ? '1px solid orange' : undefined,
      ...style,
      ...sx,
    }

    const buttonElement = (
      <LoadingButton ref={ref} variant="contained" style={buttonStyles} {...rest}>
        {children}
      </LoadingButton>
    )

    if (href) {
      return (
        <a
          href={href}
          style={{
            textDecoration: 'none',
            color: 'inherit',
            backgroundColor: 'transparent',
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {buttonElement}
        </a>
      )
    }

    return buttonElement
  }
)

SMPrimaryButton.displayName = 'SMPrimaryButton'

export const SMIconButton = ({ onClick, style, b, children, ...rest }: any) => {
  const handleClick = (e: any) => {
    e.preventDefault() // prevent the default action of the browser
    e.stopPropagation() // prevent the click from propagating to the parent
    onClick(e)
  }
  return (
    <IconButton
      {...rest}
      onClick={handleClick}
      style={{
        border: b && '1px solid orange',
        ...style,
      }}
    >
      {children}
    </IconButton>
  )
}

export const SMALinkButton = ({ href, eventName, children, style, sx, ...rest }: any) => {
  const { auth, user } = useContext(AppContext)
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: 'none',
        color: 'inherit',
        backgroundColor: 'transparent',
      }}
      {...rest}
    >
      <Button
        onClick={(e: any) => {
          logSMEvent(eventName, {
            rut: (auth && auth?.rut) || 'none',
            user: user || 'none',
          })
          e.stopPropagation() // prevent the click from propagating to the parent
        }}
        variant="contained"
        style={{ textTransform: 'none', ...style, ...sx }}
      >
        {children}
      </Button>
    </a>
  )
}

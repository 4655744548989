import { getUrlParam } from './others'

export const isiOS = !!navigator.userAgent.match(/iPhone|iPad|iPod/i)
export const isAndroid = /Android/i.test(navigator.userAgent)
export const isInSafari = /^((?!chrome|android|crios|fxios|opera|edg|msie|trident).)*safari/i.test(
  navigator.userAgent
)
export const isStandAlone = window.matchMedia('(display-mode: standalone)').matches
export const isStandAlone2 = (window?.navigator as any)?.standalone
export const isAndroid2 = document?.referrer?.includes('android-app://')
export const isSamsung = window.navigator.userAgent.includes('Samsung')
export const isMinimalUI = window.matchMedia('(display-mode: minimal-ui)').matches
export const isPwaModeParamActive = getUrlParam('pwa') === 'true'

export const isAppInstalled =
  isStandAlone || isStandAlone2 || isAndroid2 || (isSamsung && isMinimalUI) || isPwaModeParamActive
export const isInstagram = navigator?.userAgent?.includes('Instagram')
export const isChrome =
  navigator.userAgent.includes('Chrome') && navigator.vendor.includes('Google Inc.')

export const isMobile = isiOS || isAndroid

import React, { useContext } from 'react'

import { AppContext } from '../../../contexts/AppContext'
import { ALL_DAYS, FilterOptionsEnum, numberRegion } from '../../../consts/filterOptions'
import { ALL_REGIONES, RegionesEnum } from '../../../types/regiones'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { primary80White, primaryPrimary } from '../../../style'
// import { MAINTAGV3Enum } from '../../../types/mainTagV3'
// import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded'
import TodayRoundedIcon from '@mui/icons-material/TodayRounded'
import { normalizeDaysToText } from '../../../helpers/others'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import { ALLMAINTAGSV3, MAINTAGV3Label } from '../../../types/mainTagV3'

const LOGO_CLUB = 'LOGO_CLUB'

export const FastFilter = ({
  label,
  icon,
  isActive,
  onClick,
  showExpand,
  sx,
}: {
  label: string
  icon: any
  isActive: boolean
  onClick: () => void
  showExpand?: boolean
  sx?: any
}) => {
  const { filtersSelected, clubs } = useContext(AppContext)

  let iconComponent = icon
  if (icon === LOGO_CLUB) {
    iconComponent = <CreditCardRoundedIcon fontSize="small" />
    if (filtersSelected[FilterOptionsEnum.TARJETAS].length === 1) {
      iconComponent = (
        <div
          style={{
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={clubs ? clubs[filtersSelected[FilterOptionsEnum.TARJETAS][0]]?.img : ''}
            style={{
              width: 16,
              height: 16,
              objectFit: 'contain',
            }}
          />
        </div>
      )
    }
  }
  return (
    <div
      onClick={onClick}
      style={{
        cursor: 'pointer',
        display: 'flex',
        gap: 6,
        width: 'fit-content',
        padding: '6px 10px 6px 10px',
        borderRadius: '20px',
        backgroundColor: isActive ? primary80White : 'transparent',
        color: isActive ? primaryPrimary : primaryPrimary,
        border: isActive ? '' : '1px solid',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        ...sx,
      }}
    >
      {iconComponent}
      <p>{label}</p>
      {isActive && !showExpand && <CloseRoundedIcon fontSize="small" />}
      {showExpand && <ExpandMoreRoundedIcon fontSize="small" />}
    </div>
  )
}

export const FastFilters = () => {
  const { filtersSelected, setFiltersSelected, clubs, setNavButtonClicked } = useContext(AppContext)
  const fastFilters = []
  // const isRestoBarSelected =
  //   filtersSelected[FilterOptionsEnum.MAINTAGV3].includes(MAINTAGV3Enum.RESTOBAR_PRESENCIAL) ||
  //   filtersSelected[FilterOptionsEnum.MAINTAGV3].length === 0

  // filtro de "Restobar" (solo se muestra si no esta seleccionado)
  // if (!isRestoBarSelected) {
  //   fastFilters.push(
  //     <FastFilter
  //       label="Incluir Restaurantes y Bares"
  //       icon={<RestaurantRoundedIcon fontSize="small" />}
  //       isActive={false}
  //       onClick={() => {
  //         setFiltersSelected({
  //           ...filtersSelected,
  //           [FilterOptionsEnum.MAINTAGV3]: [
  //             ...filtersSelected[FilterOptionsEnum.MAINTAGV3],
  //             MAINTAGV3Enum.RESTOBAR_PRESENCIAL,
  //           ],
  //         })
  //       }}
  //     />
  //   )
  // }

  // si esta en el mapa, mostrar botón para quitar las cadenas
  // if (discountDisplayMode === DiscountsDisplayEnum.MAP) {
  //   fastFilters.push(
  //     <FastFilter
  //       label={includesCadenas ? 'Excluir cadenas de restaurantes' : 'Excluyendo cadenas'}
  //       icon={null}
  //       isActive={!includesCadenas}
  //       onClick={() => {
  //         setFiltersSelected({
  //           ...filtersSelected,
  //           [FilterOptionsEnum.MAP]: includesCadenas ? [] : [mapOptions.INCLUIR_FRANQUICIAS],
  //         })
  //       }}
  //     />
  //   )
  // }

  // filtro de "30% dcto." (solo se muestra si hay mas de 5 descuentos con mas de 30% de dcto)
  const minDiscountAmount = filtersSelected[FilterOptionsEnum.MIN_DISCOUNT_AMOUNT]
  const isMinDiscountActive = minDiscountAmount > 0
  const suggest30 = true
  // discounts.filter((x) => x.cantidadDescuento && parseInt(x.cantidadDescuento) > 30).length >= 1
  if (isMinDiscountActive || (!isMinDiscountActive && suggest30)) {
    fastFilters.push(
      <FastFilter
        key={'minDiscount'}
        label={isMinDiscountActive ? `> ${minDiscountAmount}% dcto` : '> 30% dcto.'}
        icon={null}
        isActive={isMinDiscountActive ? true : false}
        onClick={() => {
          setFiltersSelected({
            ...filtersSelected,
            [FilterOptionsEnum.MIN_DISCOUNT_AMOUNT]: isMinDiscountActive ? 0 : 30,
          })
        }}
      />
    )
  }

  // filtro de dias activos (solo se muestra si esta activo el filtro)
  const isFastFilterDayActive =
    filtersSelected[FilterOptionsEnum.DIAS].length > 0 &&
    filtersSelected[FilterOptionsEnum.DIAS].length !== ALL_DAYS.length
  fastFilters.push(
    <FastFilter
      key={'dias'}
      label={
        isFastFilterDayActive
          ? normalizeDaysToText(filtersSelected[FilterOptionsEnum.DIAS].join(','))
          : 'Días'
      }
      icon={<TodayRoundedIcon fontSize="small" />}
      isActive={isFastFilterDayActive}
      onClick={() => {
        setNavButtonClicked(FilterOptionsEnum.DIAS)
      }}
      showExpand={true}
    />
  )

  // filtro de "Santiago" o Region XII + 3
  // si están todas las regiones seleccionadas => mostramos 'Santiago' como inactivo y como one click
  // si no, mostramos las regiones seleccionadas  como activo y con expand
  const allRegionesSelected =
    filtersSelected[FilterOptionsEnum.UBICACION].length === 0 ||
    filtersSelected[FilterOptionsEnum.UBICACION].length === ALL_REGIONES.length

  if (allRegionesSelected) {
    fastFilters.push(
      <FastFilter
        key={'location'}
        label={'Sólo en Santiago'}
        icon={<LocationOnRoundedIcon fontSize="small" />}
        isActive={false}
        showExpand={false}
        onClick={() => {
          setFiltersSelected({
            ...filtersSelected,
            [FilterOptionsEnum.UBICACION]: [RegionesEnum.METROPOLITANA],
          })
        }}
      />
    )
  } else {
    const amountRegionesSelected = filtersSelected[FilterOptionsEnum.UBICACION].length
    const region = filtersSelected[FilterOptionsEnum.UBICACION][0]
    let label = region === RegionesEnum.METROPOLITANA ? 'Stgo.' : `${numberRegion[region]} Región`
    if (amountRegionesSelected > 1) {
      label += ` +${amountRegionesSelected - 1}`
    }
    fastFilters.push(
      <FastFilter
        key={'location_2'}
        label={label}
        icon={<LocationOnRoundedIcon fontSize="small" />}
        isActive={true}
        showExpand={true}
        onClick={() => {
          setNavButtonClicked(FilterOptionsEnum.UBICACION)
        }}
      />
    )
  }

  // filtro de tarjetas (siempre se muestra)
  const totalClubs = Object.keys(clubs || []).length
  const amountOfClubsSelected = filtersSelected[FilterOptionsEnum.TARJETAS].length || totalClubs
  const allClubsSelected = amountOfClubsSelected === totalClubs || amountOfClubsSelected === 0
  fastFilters.push(
    <FastFilter
      key={'tarjetas'}
      label={
        allClubsSelected
          ? 'Tarjetas'
          : amountOfClubsSelected > 1
          ? ` ${amountOfClubsSelected} tarjetas`
          : ' 1 tarjeta'
      }
      icon={allClubsSelected ? <CreditCardRoundedIcon fontSize="small" /> : LOGO_CLUB}
      isActive={allClubsSelected ? false : true}
      onClick={() => {
        setNavButtonClicked(FilterOptionsEnum.TARJETAS)
      }}
      showExpand={true}
    />
  )

  const amountOfCategoriesSelected =
    filtersSelected[FilterOptionsEnum.MAINTAGV3].length || ALLMAINTAGSV3.length
  const allCategoriesSelected = amountOfCategoriesSelected === ALLMAINTAGSV3.length
  const filterCategoryLabel = allCategoriesSelected
    ? 'Categorías'
    : amountOfCategoriesSelected === 1
    ? MAINTAGV3Label[filtersSelected[FilterOptionsEnum.MAINTAGV3][0]]
    : `${amountOfCategoriesSelected} categorías`

  fastFilters.push(
    <FastFilter
      key={'categories'}
      label={filterCategoryLabel}
      icon={null}
      isActive={!allCategoriesSelected}
      onClick={() => {
        setNavButtonClicked(FilterOptionsEnum.MAINTAGV3)
      }}
      showExpand={true}
    />
  )

  // los margenes de top y bottom los metemos en los filterFrames, en caso de que no haya ninguno
  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
        padding: 12,
        overflowX: 'auto',
        // do not show scrollbar
        scrollbarWidth: 'none',
        alignItems: 'center',
      }}
    >
      {fastFilters}
    </div>
  )
}

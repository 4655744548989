import { daysIndex } from '../consts/filterOptions'

const expireDayFilter = 0.5 * 60 * 60 * 1000 // 30 mins en miliseconds
const now = new Date()
const nowNumber = now.getTime()
const todayIndex = now.getDay() // 0 domingo, 6 sabado

// This doesnt work, but im not using it anyway.
export const getDaysFilters = (cookies: any, setCookie: any, amountOfVisits: string) => {
  let { lastTimeAppUsed } = cookies
  lastTimeAppUsed = lastTimeAppUsed && parseInt(lastTimeAppUsed, 10)
  let daysFilter = []
  if (amountOfVisits === '1') daysFilter = [daysIndex[todayIndex]]
  // si es que el usuario tiene en las cookies los dias guardados,
  // y el tiempo que ha pasado desde ese momento es menor al límite de vida
  // entonces mantenemos sus últimos cambios
  if (
    amountOfVisits !== '1' &&
    cookies?.filtersSelected?.Dias &&
    lastTimeAppUsed &&
    nowNumber - lastTimeAppUsed < expireDayFilter
  )
    daysFilter = cookies?.filtersSelected?.Dias

  // cada vez que el usuario se mete, le dará un tiempo extra de vida a la permanencia de los dias
  setCookie('lastTimeAppUsed', now.getTime(), {
    path: '/',
    expires: new Date(1830000000000),
  })
  return daysFilter
}

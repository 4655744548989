import { neutralWhite } from '../style'

export const MAP_DISCOUNT_CARD_HEIGHT_BIG = '250px'
export const MAP_DISCOUNT_CARD_HEIGHT_SMALL = '80px'

export const LIST_DISCOUNT_CARD_STYLE = {
  maxWidth: '320px',
  height: '196px',
  width: '100%',
  borderRadius: '25px',
  backgroundColor: neutralWhite,
  cursor: 'pointer',
  display: 'flex',
  overflow: 'hidden',
}

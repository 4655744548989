import { useEffect, useRef } from 'react'

type ObserverOptions = {
  root?: Element | null
  rootMargin?: string
  threshold?: number | number[]
}

const useObserver = (onObserve: () => void, options: ObserverOptions = { threshold: 0.5 }): any => {
  const elementRef = useRef<HTMLElement>(null)

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onObserve()
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersection, options)

    if (elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current)
      }
    }
  }, [onObserve, options])

  return elementRef
}

export default useObserver

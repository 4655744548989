import { normalizeText } from './normalizeText'
import { benefitTypes, DaysEnums, FilterOptionsType } from '../consts/filterOptions'
import { Discount } from '../types/discount'
import { discountDependsOnRegion, MAINTAGV3Enum } from '../types/mainTagV3'
import { subTarjetaId } from './subTarjetaId'
import { ClubEnums } from '../types/clubs'
import { RegionesEnum } from '../types/regiones'

export const filtersSelectedExplicitlyHasTarjeta = (
  filtersSelected: FilterOptionsType,
  tarjeta: ClubEnums,
  filterOptions: FilterOptionsType
) => {
  return (
    filtersSelected.Tarjetas.includes(tarjeta) &&
    filtersSelected.Tarjetas.length !== filterOptions.Tarjetas.length
  )
}

export const filtersSelectedIncludeAnyOfDays = (
  filtersSelected: FilterOptionsType,
  days: DaysEnums[]
) => {
  return days.some((day) => filtersSelected.Dias.includes(day)) || filtersSelected.Dias.length === 0
}

export const filtersSelectedIncludeAnyOfUbicacion = (
  filtersSelected: FilterOptionsType,
  ubicacion: RegionesEnum[]
) => {
  return (
    ubicacion.some((region) => filtersSelected.Ubicacion.includes(region)) ||
    filtersSelected.Ubicacion.length === 0
  )
}

export const filterDiscounts = (
  allDiscount: Discount[],
  searchText: string,
  filtersSelected: FilterOptionsType,
  filterOptions: FilterOptionsType
) => {
  if (!allDiscount) return allDiscount
  const filteredDiscounts = allDiscount.filter((discount) => {
    if (discount.parentId) return false
    // Filter Search
    let s = searchText?.toLocaleLowerCase()?.replace('#', '') || ''
    s = normalizeText(s)
    if (
      s.length > 2 &&
      !discount?.club?.toLowerCase().includes(s) &&
      !normalizeText(discount?.titulo)?.toLowerCase().includes(s) &&
      !normalizeText(discount?.descripcion)?.toLowerCase().includes(s) &&
      !discount?.ubicacion?.toLowerCase().includes(s) &&
      !discount?.categoria?.toLowerCase().includes(s) &&
      !discount?.diasNormalizados?.toLowerCase().includes(s) &&
      !discount?.tags?.toLowerCase().includes(s) &&
      normalizeText(discount.id.toLowerCase()) !== s
    )
      return false

    // Filter Clubs
    if (!filtersSelected.Tarjetas.includes(discount.club) && discount.club !== 'AD') {
      // si el club del discount esta seleccionado, pasamos
      const allClubsIncluded = [0, filterOptions.Tarjetas.length].includes(
        filtersSelected.Tarjetas.length
      )
      if (!allClubsIncluded) return false
      const discountClubIsInListOfTarjetas = filterOptions.Tarjetas.includes(discount.club)
      if (!discountClubIsInListOfTarjetas) return false
    }

    // filter SubTarjeta
    if (discount.subTarjeta && discount.subTarjeta.length > 0 && filtersSelected.SubTarjeta) {
      // recordar que en filterSelected se guarda como id de la subTarjeta club.id-subtarJeta.id a través de subTarjetaId
      const amountSubTarjetaSelected = filtersSelected.SubTarjeta.filter(
        (st) => st.includes(discount.club) // subTarjetaSelected que sean de discount.club
      ).length
      const maxSubTarjetaAmount = filterOptions.SubTarjeta.filter((st) =>
        st.includes(discount.club)
      ).length
      if (amountSubTarjetaSelected > 0 && amountSubTarjetaSelected < maxSubTarjetaAmount) {
        // ninguna de las subTarjetas hace match con las del descuento, la sacamos
        if (
          !discount.subTarjeta
            .split(';')
            .some((st) =>
              filtersSelected.SubTarjeta.includes(subTarjetaId(discount.club, st.trim()))
            )
        )
          return false
      }
    }

    // Filter Days
    if (
      filtersSelected.Dias.length !== filterOptions.Dias.length &&
      filtersSelected.Dias.length !== 0
    ) {
      const matchAnyDaySelected = filtersSelected.Dias.some((day) => {
        return discount?.diasNormalizados?.includes(day)
      })
      if (
        !matchAnyDaySelected &&
        discount?.diasNormalizados !== 'NOT FOUND' &&
        discount?.diasNormalizados !== ''
      )
        return false
    }

    // Filter Location
    if (
      filtersSelected?.Ubicacion?.length !== filterOptions?.Ubicacion?.length &&
      filtersSelected.Ubicacion.length !== 0 &&
      discountDependsOnRegion(discount) &&
      discount?.regiones?.length !== 0
    ) {
      // return false if discount.regiones does not have any of the regiones in filtersSelected.Ubicacion

      const matchAnyLocationSelected = filtersSelected.Ubicacion.some((region) => {
        return discount?.regiones?.includes(region)
      })
      if (!matchAnyLocationSelected) return false
    }

    // Filter Type of benefit. Sí sólo quiere ver descuentos, retornamos false cuando no lo sea
    if (
      // esto nunca puede pasar, pero igual lo dejo por si alguna cookie nos juega mala pasada
      filtersSelected.BenefitType.length !== filterOptions.BenefitType.length &&
      // esto tampoco
      filtersSelected.BenefitType.length !== 0 &&
      // esto sí
      !filtersSelected.BenefitType.includes(benefitTypes.ALL_DISCOUNTS) &&
      // no es de bencina
      !discount.mainTagV3?.includes(MAINTAGV3Enum.BENCINA)
    ) {
      const isDiscount = discount.cantidadDescuento
      if (!isDiscount) return false
    }

    if (
      filtersSelected.MinDiscountsAmount !== 0 &&
      discount.cantidadDescuento &&
      parseInt(discount.cantidadDescuento, 10) > 0
    )
      if (parseInt(discount.cantidadDescuento, 10) < filtersSelected.MinDiscountsAmount)
        return false

    // Filtro MainTagV3
    if (
      filtersSelected.MainTagV3.length !== filterOptions.MainTagV3.length &&
      filtersSelected.MainTagV3.length !== 0
    ) {
      if (!discount.mainTagV3) return false
      let discountContainACategory = false
      let discountHasASelectedCategory = false
      const isOtherSelected = filtersSelected.MainTagV3.includes(MAINTAGV3Enum.OTROS)
      discount.mainTagV3.forEach((mt) => {
        if (filterOptions.MainTagV3.includes(mt) && mt !== MAINTAGV3Enum.OTROS)
          discountContainACategory = true
        // TODO: eliminar esto cuando los filtros se limpien en el app.js
        // revisamos que este seleccionada y que exista entre las opciones
        if (filtersSelected.MainTagV3.includes(mt) && filterOptions.MainTagV3.includes(mt))
          discountHasASelectedCategory = true
      })
      // si no esta selecionado "otras" y el descuento no tiene las categorias seleccionadas
      if (!isOtherSelected && !discountHasASelectedCategory) return false
      // si esta selecionado unicamente "otras" y el descuento sí tiene una categoria
      if (isOtherSelected && filtersSelected.MainTagV3.length === 1 && discountContainACategory)
        return false
      // si esta seleccionado "otras" y tmbn otras categorias estan seleccionadas
      // y el descuento sí tiene una categoria pero no tiene una de las categorias seleccionadas
      if (
        isOtherSelected &&
        filtersSelected.MainTagV3.length > 1 &&
        discountContainACategory &&
        !discountHasASelectedCategory
      )
        return false
    }

    // Solo ADMIN: filtrar por OnlyReserved, WithoutLocal, WithoutGMapsResults
    if (filtersSelected.WithoutLocal && discount.local) return false
    if (filtersSelected.WithoutGMapsResults && discount.gMapsResults) return false

    return true
  })

  return filteredDiscounts
}

export const sortDiscount = (discounts: Discount[], sortOption: any) => {
  const discountsCopy = [...discounts]
  const sortedDiscounts = discountsCopy.sort((x, y) => {
    if (sortOption === 'KMS') {
      // ordenamos por distance
      const xDistance = x.XClosestLocation?.distance || 999999999999
      const yDistance = y.XClosestLocation?.distance || 999999999999
      if (xDistance > yDistance) return 1
      if (xDistance < yDistance) return -1
      return 0
    }
    if (sortOption === 'DCTO') {
      // ordenamos por cantidad de descuento
      let xAmount = parseInt(x.cantidadDescuento, 10) || 0
      let yAmount = parseInt(y.cantidadDescuento, 10) || 0
      if (xAmount >= 90) xAmount = 0
      if (yAmount >= 90) yAmount = 0
      if (xAmount < yAmount) return 1
      if (xAmount > yAmount) return -1
      return 0
    }
    return 0
  })
  return sortedDiscounts
}

import React, { useEffect, useState, useContext } from 'react'
import { Button } from '@mui/material'

import '../../../App.css'
import styles from './discount.module.css'
import { AppContext } from '../../../contexts/AppContext'
import { filterDiscounts } from '../../../helpers/filterDiscounts'
import { sortOptions } from '../../../consts/filterOptions'
import { logSMEvent } from '../../../events/logEvent'
import { DeviceInfo } from '../components/DeviceInfo'
import { TopDiscountDisplayFrame } from '../components/TopDiscountDisplayFrame'
import { MapScreen } from '../components/map/MapScreen'
import { DiscountScreen } from '../components/DiscountScreen'
import { setUrlParam, getUrlParam } from '../../../helpers/others'
import { useFilterOptions } from '../../../hooks/filterOptions'
import { DiscountsDisplayEnum } from '../../../enums/DiscountsViewsEnum'
import { DiscountsTableWithMultiplePanels } from '../components/table/DiscountsTableView'
import NoDiscountsFound from '../components/NoDiscountsFound'
import { Discount } from '../../../types/discount'
import { primary80White } from '../../../style'
import SortButton from '../components/SortButton'
import { FastFilters } from '../components/FastFilters'

const { discountScreen } = styles

const DisplayButton = ({ isSelected, children, sx, ...props }: any) => {
  return (
    <Button
      sx={{
        height: 32,
        borderRadius: 20,
        textTransform: 'none',
        backgroundColor: isSelected ? primary80White : 'transparent',
        '&:hover': {
          backgroundColor: isSelected ? primary80White : 'transparent',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

const DisplayBody = ({
  discountDisplayMode,
  discountsToShow,
  search,
  handleChangeSearch,
  isStar,
  discounts,
}: {
  discountDisplayMode: DiscountsDisplayEnum
  discountsToShow: Discount[]
  search: string
  handleChangeSearch: (newSearch: string) => void
  isStar: boolean
  discounts: Discount[]
}) => {
  if (discountsToShow.length === 0) {
    return <NoDiscountsFound search={search} handleChangeSearch={handleChangeSearch} />
  }
  if (discountDisplayMode === DiscountsDisplayEnum.NOT_DECIDED) {
    return <p>Not decided</p>
  }
  if (discountDisplayMode === DiscountsDisplayEnum.TABLE) {
    return (
      <DiscountsTableWithMultiplePanels
        isStar={isStar}
        discountsWithParentId={discounts.filter((d) => d.parentId)}
        discountsToShow={discountsToShow}
      />
    )
  }
  if (discountDisplayMode === DiscountsDisplayEnum.MAP) {
    return <MapScreen isStar={isStar} discountsToShow={discountsToShow} />
  }
  if (discountDisplayMode === DiscountsDisplayEnum.LIST) {
    return (
      <DiscountScreen
        discountsToShow={discountsToShow}
        search={search}
        handleChangeSearch={handleChangeSearch}
        isSavedDiscountsScreen={false}
        isStar={isStar}
      />
    )
  }
  return null
}

export const DiscountDisplays = function ({
  isStar,
  discounts,
  discountsSortedByDcto,
  discountsSortedByKms,
  displayMode,
}: {
  isStar: boolean
  discounts: Discount[]
  discountsSortedByDcto: Discount[]
  discountsSortedByKms: Discount[]
  displayMode: DiscountsDisplayEnum
}) {
  const urlSearch = getUrlParam('search')
  const filterOptions = useFilterOptions()
  const { filtersSelected } = useContext(AppContext)
  const [discountsToShow, setDiscountsToShow] = useState<Discount[]>([])
  const [search, setSearch] = useState(urlSearch)
  const [discountDisplayMode, setDiscountDisplayMode] = useState(displayMode)

  // cambiar search cada vez que la url cambia, esti es redunante excepto cuando se abre la app
  useEffect(() => {
    setSearch(urlSearch)
  }, [urlSearch])

  const handleChangeSearch = (newSearch: string) => {
    // cambiar url cada vez que el search cambia
    setUrlParam('search', newSearch)
    setSearch(newSearch)
  }

  const changeDisplayMode = (mode: DiscountsDisplayEnum) => {
    logSMEvent('CHANGE_DISPLAY_MODE', {
      displayMode: mode,
    })
    setDiscountDisplayMode(mode)
  }

  // filtramos los descuentos
  useEffect(() => {
    if (discounts) {
      if (filtersSelected.Sort.includes(sortOptions.KMS)) {
        setDiscountsToShow(
          filterDiscounts(discountsSortedByKms, search, filtersSelected, filterOptions)
        )
      } else if (filtersSelected.Sort.includes(sortOptions.DCTO)) {
        setDiscountsToShow(
          filterDiscounts(discountsSortedByDcto, search, filtersSelected, filterOptions)
        )
      } else {
        setDiscountsToShow(filterDiscounts(discounts, search, filtersSelected, filterOptions))
      }
    }
  }, [
    discounts,
    search,
    filtersSelected,
    discountsSortedByDcto,
    discountsSortedByKms, // this should change every time the users location changes
    filterOptions,
  ])

  if (search === 'infodeldispositivo' || search === 'Infodeldispositivo') {
    return <DeviceInfo />
  }

  return (
    <div className={discountScreen}>
      <TopDiscountDisplayFrame search={search} handleChangeSearch={handleChangeSearch} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 10,
          boxShadow: '0px 4px 4px rgba(180, 181, 186)', // this means
        }}
      >
        {discountDisplayMode === DiscountsDisplayEnum.LIST ? (
          <div style={{ minWidth: 90 }} />
        ) : (
          <div />
        )}
        <div
          style={{
            display: 'flex',
          }}
        >
          {/* <ButtonGroup variant="outlined"> */}
          <DisplayButton
            onClick={() => {
              changeDisplayMode(DiscountsDisplayEnum.LIST)
            }}
            isSelected={discountDisplayMode === DiscountsDisplayEnum.LIST}
          >
            Lista
          </DisplayButton>
          <DisplayButton
            onClick={() => {
              changeDisplayMode(DiscountsDisplayEnum.TABLE)
            }}
            isSelected={discountDisplayMode === DiscountsDisplayEnum.TABLE}
          >
            Tabla
          </DisplayButton>
          <DisplayButton
            onClick={() => {
              changeDisplayMode(DiscountsDisplayEnum.MAP)
            }}
            isSelected={discountDisplayMode === DiscountsDisplayEnum.MAP}
          >
            Mapa
          </DisplayButton>
          {/* </ButtonGroup> */}
        </div>
        {discountDisplayMode === DiscountsDisplayEnum.LIST ? <SortButton /> : <div />}
      </div>
      <FastFilters />
      <DisplayBody
        discountDisplayMode={discountDisplayMode}
        discountsToShow={discountsToShow}
        search={search}
        handleChangeSearch={handleChangeSearch}
        isStar={isStar}
        discounts={discounts}
      />
    </div>
  )
}

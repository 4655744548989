import React, { useContext, useEffect, useState } from 'react'
import { Discount } from '../../../types/discount'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { addUtmParamsToUrl } from '../../../helpers/others'
import { SMP } from '../../../SMComponents/SMText'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import { neutralWhite, primaryPrimary } from '../../../style'
import { logSMEvent } from '../../../events/logEvent'
import { MAINTAGV3Enum } from '../../../types/mainTagV3'
import { AppContext } from '../../../contexts/AppContext'
import useObserver from '../../../hooks/useObserver'

export const CTAButton = ({
  discount,
  source,
  category,
}: {
  discount: Discount
  source: string
  category?: MAINTAGV3Enum | 'AD'
}) => {
  const { auth } = useContext(AppContext)
  const [wasObserved, setWasObserved] = useState(false) // is or was observed

  const handleObservation = () => {
    setWasObserved(true)
  }

  const componentRef = useObserver(handleObservation)

  useEffect(() => {
    if (wasObserved) {
      const discountSummaryToLog = {
        id: discount.id,
        club: discount.club,
      }
      logSMEvent(`CTA_BUTTON_OBSERVED_${source}`, { ...discountSummaryToLog })
    }
  }, [wasObserved])

  if (!discount.CTA || !discount.CTA.text || !discount.CTA.url) {
    return null
  }
  return (
    <SMPrimaryButton
      ref={componentRef}
      sx={{
        minWidth: 0, // to override the default minWidth
        minHeight: 0, // to override the default minHeight
        maxWidth: 240,
        maxHeight: 30,
        padding: 10,
        textAlign: 'start',
        color: neutralWhite,
        backgroundColor: primaryPrimary,
        gap: 6,
      }}
      onClick={(e: any) => {
        e.stopPropagation()
        logSMEvent(`CTA_BUTTON_CLICKED_${source}`, {
          discountId: discount.id,
          discountTitle: discount.titulo,
          discountClub: discount.club,
          category,
          isPromotedDiscount: discount.promoted,
          userId: auth ? auth.id : null,
          ctaText: discount.CTA?.text,
          ctaUrl: discount.CTA?.url,
          authPhoneNumber: auth ? auth.phoneNumber : null,
        })
      }}
      href={addUtmParamsToUrl({ url: discount.CTA.url, id: discount.id })}
    >
      <SMP white>{discount.CTA.text}</SMP>
      <OpenInNewRoundedIcon htmlColor={neutralWhite} sx={{ fontSize: 14 }} />
    </SMPrimaryButton>
  )
}

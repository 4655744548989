import { useState, useEffect } from 'react'

export function useViewportHeight() {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return viewportHeight
}

const MOBILE_BREAKPOINT = 700

export function useViewportWidth() {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth)
  const [isMobile, setIsMobile] = useState(viewportWidth <= MOBILE_BREAKPOINT)

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setIsMobile(viewportWidth <= MOBILE_BREAKPOINT)
  }, [viewportWidth])

  return [viewportWidth, isMobile]
}

import React, { useContext, useState } from 'react'
import { neutral90White, neutralWhite, primary20Black } from '../../../style'
import { SMP } from '../../../SMComponents/SMText'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'
import { AppContext } from '../../../contexts/AppContext'
import { Switch } from '@mui/material'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { request } from '../../../helpers/request'

export const SuggestedBottoms = ({ isStar }: any) => {
  const { filtersSelected, setFiltersSelected, handleOpenSnackBar } = useContext(AppContext)
  const [isLoadingUpdateBucketFile, setIsLoadingUpdateBucketFile] = useState(false)
  if (!isStar) return null

  return (
    <SMHorizontalDiv
      sx={{
        marginLeft: 25,
        // wrap the content if it's too long
        flexWrap: 'wrap',
        // overflowX: 'auto',
        // width: 'calc(100% - 25px)',
        gap: 4,
      }}
    >
      <SMPrimaryButton
        onClick={() => {
          setFiltersSelected({
            ...filtersSelected,
            OnlyReserved: !filtersSelected.OnlyReserved,
          })
        }}
        variant="outlined"
        disableRipple={false} // enable wave effect
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          textWrap: 'nowrap',
          alignItems: 'center',
          borderRadius: '10px',
          color: primary20Black,
          border: '1px solid',
          margin: '0px 5px',
          borderColor: neutral90White,
          backgroundColor: neutralWhite,
          textTransform: 'none',
          height: '38px',
        }}
      >
        <SMHorizontalDiv sx={{ gap: 0 }}>
          <SMP>Sólo reservable</SMP>
          <Switch checked={filtersSelected.OnlyReserved || false} />
        </SMHorizontalDiv>
      </SMPrimaryButton>
      <SMPrimaryButton
        onClick={() => {
          setFiltersSelected({
            ...filtersSelected,
            WithoutLocal: !filtersSelected.WithoutLocal,
          })
        }}
        variant="outlined"
        disableRipple={false} // enable wave effect
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          textWrap: 'nowrap',
          alignItems: 'center',
          borderRadius: '10px',
          color: primary20Black,
          border: '1px solid',
          margin: '0px 5px',
          borderColor: neutral90White,
          backgroundColor: neutralWhite,
          textTransform: 'none',
          height: '38px',
        }}
      >
        <SMHorizontalDiv sx={{ gap: 0 }}>
          <SMP>Sin local</SMP>
          <Switch checked={filtersSelected.WithoutLocal || false} />
        </SMHorizontalDiv>
      </SMPrimaryButton>
      <SMPrimaryButton
        onClick={() => {
          setFiltersSelected({
            ...filtersSelected,
            WithoutGMapsResults: !filtersSelected.WithoutGMapsResults,
          })
        }}
        variant="outlined"
        disableRipple={false} // enable wave effect
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          textWrap: 'nowrap',
          alignItems: 'center',
          borderRadius: '10px',
          color: primary20Black,
          border: '1px solid',
          margin: '0px 5px',
          borderColor: neutral90White,
          backgroundColor: neutralWhite,
          textTransform: 'none',
          height: '38px',
        }}
      >
        <SMHorizontalDiv sx={{ gap: 0 }}>
          <SMP>Sin gMapsResults</SMP>
          <Switch checked={filtersSelected.WithoutGMapsResults || false} />
        </SMHorizontalDiv>
      </SMPrimaryButton>
      <SMPrimaryButton
        loading={isLoadingUpdateBucketFile}
        sx={{ backgroundColor: isLoadingUpdateBucketFile ? 'grey' : 'purple' }}
        onClick={async () => {
          setIsLoadingUpdateBucketFile(true)
          const response = await request('update_bucket_file')
          console.log(response?.data || 'no data')
          handleOpenSnackBar({
            message: response?.message || 'Error',
          })
          setIsLoadingUpdateBucketFile(false)
        }}
      >
        WARNING: Actualizar BBDD
      </SMPrimaryButton>
    </SMHorizontalDiv>
  )
}
